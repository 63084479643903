@import '../../node_modules/swiper/swiper-bundle.min.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'FS Elliot Web';
    src: url('../fonts/FSElliotWeb-Regular.eot');
    src:
      url('../fonts/FSElliotWeb-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/FSElliotWeb-Regular.woff2') format('woff2'),
      url('../fonts/FSElliotWeb-Regular.woff') format('woff'),
      url('../fonts/FSElliotWeb-Regular.ttf') format('truetype'),
      url('../fonts/FSElliotWeb-Regular.svg#FSElliotWeb-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'FS Elliot Web';
    src: url('../fonts/FSElliotWeb-Heavy.eot');
    src:
      url('../fonts/FSElliotWeb-Heavy.eot?#iefix') format('embedded-opentype'),
      url('../fonts/FSElliotWeb-Heavy.woff2') format('woff2'),
      url('../fonts/FSElliotWeb-Heavy.woff') format('woff'),
      url('../fonts/FSElliotWeb-Heavy.ttf') format('truetype'),
      url('../fonts/FSElliotWeb-Heavy.svg#FSElliotWeb-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'FS Elliot Web';
    src: url('../fonts/FSElliotWeb-Bold.eot');
    src:
      url('../fonts/FSElliotWeb-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/FSElliotWeb-Bold.woff2') format('woff2'),
      url('../fonts/FSElliotWeb-Bold.woff') format('woff'),
      url('../fonts/FSElliotWeb-Bold.ttf') format('truetype'),
      url('../fonts/FSElliotWeb-Bold.svg#FSElliotWeb-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
}
